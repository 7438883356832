// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Measure from 'react-measure';
import classNames from 'classnames';
import { Layout, Columns, Column } from '@plesk/ui-library';
import { useLocalStorage, useMediaQuery } from 'common/hooks';
import CookiePolicy from 'plesk/components/CookiePolicy';
import JswComponent from 'plesk/components/JswComponent';
import StatusMessages from 'plesk/components/StatusMessages';
import LicenseStatus from './LicenseStatus';
import PageHeader from './PageHeader';
import PageSidebar from './PageSidebar';
import PageFooter from './PageFooter';
import PageContentHeader from './PageContentHeader';
import stripTags from 'helpers/stripTags';

import BRANDING_QUERY from 'queries/Branding.graphql';

const PageLayout = ({
    baseUrl,
    logo,
    showFrames,
    returnUrl,
    pageHeader,
    pageSidebar,
    pageContentHeader,
    pageTitle,
    withSecondary,
    secondary,
    width,
    bodyClass,
    integrationClass,
    children,
    isSecondaryCollapsed,
}) => {
    const { data: { viewer: user } = {} } = useQuery(BRANDING_QUERY);
    const [isClosedOnDesktop, setClosedOnDesktop] = useLocalStorage('isSidebarClosed');
    const [isClosedInResponsive, setClosedInResponsive] = useState(true);
    const sidebarCollapsed = [isClosedInResponsive && 'responsive', (isClosedOnDesktop === 'true') && 'desktop'].filter(Boolean);
    const isResponsive = useMediaQuery('(max-width: 1022px)');
    const isClosed = sidebarCollapsed.includes(isResponsive ? 'responsive' : 'desktop');

    useLayoutEffect(() => {
        const documentTitleEl = document.querySelector('title');
        if (!documentTitleEl) {
            return;
        }
        documentTitleEl.innerHTML = [pageTitle && stripTags(pageTitle), user?.customTitle]
            .filter(Boolean).join(' - ');
    }, [pageTitle, user?.customTitle]);

    useLayoutEffect(() => {
        const classes = classNames(bodyClass, integrationClass).split(' ').filter(Boolean);
        classes.forEach(className => document.body.classList.add(className));
        return () => {
            classes.forEach(className => document.body.classList.remove(className));
        };
    }, [bodyClass, integrationClass]);

    if (baseUrl) {
        global.Jsw.baseUrl = baseUrl;
    }

    const handleToggleSidebar = (collapsed, mode) => {
        switch (mode) {
            case 'responsive':
                setClosedInResponsive(collapsed);
                break;
            case 'desktop':
                setClosedOnDesktop(collapsed ? 'true' : 'false');
                break;
        }
    };

    const [headerHeight, setHeaderHeight] = useState(0);
    const [headerAddonHeight, setHeaderAddonHeight] = useState(0);
    const [stickyTop, setStickyTop] = useState(0);

    useEffect(() => {
        const top = headerHeight + headerAddonHeight
            + parseInt(getComputedStyle(document.querySelector('.pul-layout__main-inner')).paddingTop)
            + (document.getElementById('classic-mode-navigation')?.getBoundingClientRect().height || 0);
        setStickyTop(top);
    }, [headerHeight, headerAddonHeight]);

    return (
        <Layout
            id="page"
            width={width}
            header={showFrames && pageHeader ? (
                <Measure bounds onResize={({ bounds }) => setHeaderHeight(bounds.height)}>
                    {({ measureRef }) => (
                        <div ref={measureRef}>
                            <PageHeader
                                returnUrl={returnUrl}
                                {...pageHeader}
                            />
                        </div>
                    )}
                </Measure>
            ) : undefined}
            headerAddon={showFrames && pageHeader ? (
                <Measure bounds onResize={({ bounds }) => setHeaderAddonHeight(bounds.height)}>
                    {({ measureRef }) => (
                        <div ref={measureRef}>
                            <CookiePolicy />
                            {pageHeader.licenseStatus ? <LicenseStatus {...pageHeader.licenseStatus} /> : null}
                        </div>
                    )}
                </Measure>
            ) : undefined}
            onSidebarToggle={handleToggleSidebar}
            sidebar={showFrames && pageSidebar ? (
                <PageSidebar
                    title={user?.customTitle}
                    logo={logo}
                    isClosed={isClosed}
                    onNodeClick={isResponsive ? () => setClosedInResponsive(true) : undefined}
                    {...pageSidebar}
                />
            ) : undefined}
            sidebarCollapsed={sidebarCollapsed}
            sidebarType="folded"
            footer={showFrames ? (
                <PageFooter />
            ) : undefined}
            contentHeader={pageContentHeader ? (
                <PageContentHeader {...pageContentHeader} />
            ) : null}
        >
            <div className="b-content">
                <Columns gap="md" vertical={740} data-type="page-content" className="b-content-wrap">
                    <Column fill id="content-body" className="b-content-main">
                        <div id="main" style={withSecondary || secondary ? { position: 'sticky', top: `${stickyTop}px` } : undefined}>
                            <StatusMessages />
                            {children}
                        </div>
                    </Column>
                    {withSecondary || secondary ? (
                        <Column
                            width={256}
                            className={classNames('b-content-side', isSecondaryCollapsed && 'b-content-side--collapsed')}
                        >
                            <div style={{ position: 'sticky', top: `${stickyTop}px` }}>
                                {typeof secondary === 'string' ? (
                                    <JswComponent>{secondary}</JswComponent>
                                ) : secondary}
                            </div>
                        </Column>
                    ) : null}
                </Columns>
            </div>
        </Layout>
    );
};

PageLayout.propTypes = {
    baseUrl: PropTypes.string,
    logo: PropTypes.object,
    showFrames: PropTypes.bool,
    returnUrl: PropTypes.string,
    pageHeader: PropTypes.shape(PageHeader.propTypes),
    pageSidebar: PropTypes.object,
    pageContentHeader: PropTypes.shape(PageContentHeader.propTypes),
    withSecondary: PropTypes.bool,
    secondary: PropTypes.any,
    isSecondaryCollapsed: PropTypes.bool,
    width: PropTypes.number,
    bodyClass: PropTypes.string,
    integrationClass: PropTypes.string,
    pageTitle: PropTypes.string,
    children: PropTypes.any,
};

PageLayout.defaultProps = {
    baseUrl: undefined,
    logo: undefined,
    showFrames: true,
    returnUrl: undefined,
    pageHeader: undefined,
    pageSidebar: undefined,
    pageContentHeader: undefined,
    pageTitle: undefined,
    withSecondary: false,
    secondary: undefined,
    isSecondaryCollapsed: false,
    width: undefined,
    bodyClass: undefined,
    integrationClass: undefined,
    children: undefined,
};

export default PageLayout;
