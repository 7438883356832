// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from './Link';

const Brand = ({ baseClassName, className, href, target, img, alt, edition }) => (
    <Link className={classNames(baseClassName, className)} to={href} state={{ reload: true }} target={target}>
        <img className={`${baseClassName}__logo`} src={img} alt={alt} />
        {edition ? <span className={`${baseClassName}__edition`}>{edition}</span> : null}
    </Link>
);

Brand.propTypes = {
    href: PropTypes.string,
    target: PropTypes.string,
    img: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    edition: PropTypes.string,
    className: PropTypes.string,
    baseClassName: PropTypes.string,
};

Brand.defaultProps = {
    href: undefined,
    target: undefined,
    edition: undefined,
    className: undefined,
    baseClassName: 'brand',
};

export default Brand;
