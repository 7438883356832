// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

export const LEVEL_VERY_WEAK = 0;
export const LEVEL_WEAK = 1;
export const LEVEL_MEDIOCRE = 2;
export const LEVEL_STRONG = 3;
export const LEVEL_STRONGER = 4;

export default {
    LEVEL_VERY_WEAK,
    LEVEL_WEAK,
    LEVEL_MEDIOCRE,
    LEVEL_STRONG,
    LEVEL_STRONGER,
};
