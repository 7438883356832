// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import TopBarProgress from 'react-topbar-progress-indicator';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { redirect, Tooltip } from 'jsw';
import routes from '../routes';
import { isMatch } from './Link';

TopBarProgress.config({
    barThickness: 2,
    barColors: {
        0: '#28aade',
        1.0: '#28aade',
    },
    shadowBlur: 0,
    className: 'top-bar-progress',
});

const updateBottomAnchor = () => {
    const bottomAnchor = document.getElementById('bottomAnchor');

    if (bottomAnchor) {
        bottomAnchor.innerHTML = new Date().getTime().toString();
    }
};

const Routes = ({ children }) => {
    const [current, setCurrent] = useState(null);
    const lastLoadedRoute = useRef(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    const route = routes.find(route => matchPath(route, location.pathname)) || null;
    const match = route ? matchPath(route, location.pathname) : {};
    const lastLoadedParams = useRef(match.params);

    const load = route => {
        if (location.state?.reload) {
            delete location.state.reload;
            navigate(location, { replace: true });
        }
        lastLoadedRoute.current = route;
        lastLoadedParams.current = match.params;

        setLoading(true);
        route.element(matchPath(route, location.pathname))
            .then(element => {
                if (route !== lastLoadedRoute.current) {
                    return;
                }

                if (element.redirect) {
                    if (isMatch(element.redirect)) {
                        navigate(element.redirect, { replace: true });
                    } else {
                        redirect(element.redirect);
                    }
                } else {
                    Tooltip.hide();
                    setCurrent(element);
                    setLoading(false);
                }
            })
            .catch(e => {
                setLoading(false);
                throw e;
            });
    };

    useEffect(() => {
        if (!route && current !== null && history.action === 'POP') {
            window.location.reload();
        } else if (!route) {
            setLoading(false);
            setCurrent(children);
        } else if (
            route !== lastLoadedRoute.current ||
            (route === lastLoadedRoute.current && JSON.stringify(lastLoadedParams.current) !== JSON.stringify(match.params)) ||
            location.state?.reload
        ) {
            load(route);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route, match.pathname, location.state?.reload]);

    useEffect(() => {
        if (!loading) {
            window.history.scrollRestoration = 'manual';
            window.scrollTo(0, 0);

            setTimeout(updateBottomAnchor, 0);
        }
    }, [loading]);

    return (
        <>
            {loading && <TopBarProgress />}
            {current}
        </>
    );
};

Routes.propTypes = {
    children: PropTypes.any,
};

Routes.defaultProps = {
    children: undefined,
};

export default Routes;
